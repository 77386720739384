<template>
  <modal-mobile name="modal-diliveryinfo" :color="selected == 3 ? '#7DBA10' : '#041D59'" :width="650" :height="285">
    <p slot="header">
      <span v-show="selected == 1">
        Odbiór w sklepie
      </span>
      <span v-show="selected == 2">
        Darmowy transport od 5000zł
      </span>
      <span v-show="selected == 3">
        Wysyłamy w 24h!
      </span>
    </p>
    <div slot="content">
      <p v-show="selected == 1">
        Odbiór w sklepie - Złóż zamówienie i odbierz je za darmo w naszym sklepie
      </p>
      <p v-show="selected == 2">
        Darmowa dostawa - na terenie Polski
      </p>
      <p v-show="selected == 3">
        Wysyłki realizowane są w dni robocze od poniedziałku do piątku . Czas dostawy zależy od przewoźnika, rodzaju przesyłki oraz adresu odbiorcy. Zazwyczaj zajmuje to do 2 dni roboczych.
      </p>
    </div>
  </modal-mobile>
</template>

<script>
import ModalMobile from 'theme/components/core/ModalMobile.vue'
import store from '@vue-storefront/core/store'
export default {
  components: {
    ModalMobile
  },
  data () {
    return {
      minCountryPerColumn: 3,
      componentLoaded: false
    }
  },
  props: {
    selected: {
      required: true,
      type: Number
    }
  },
  computed: {
    storeViews () {
      return store.state.config.storeViews
    },
    config () {
      return store.state.config
    },
    enableColumns () {
      var enableStoreViews = Object.keys(store.state.config.storeViews).filter((key) => {
        var value = store.state.config.storeViews[key]
        return (typeof value === 'object' && value.disabled === false)
      })
      return enableStoreViews.length > this.minCountryPerColumn
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.componentLoaded = true
      this.$bus.$emit('modal-show', 'modal-diliveryinfo')
    })
  },
  methods: {
    close () {
      this.$bus.$emit('modal-hide', 'modal-diliveryinfo')
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
