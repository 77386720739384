<template>
  <transition name="fade-in-up">
    <div
      class="modal"
      v-if="isVisible"
      ref="modal"
      @click.self="close"
    >
      <div class="close-box show-xs" @click="close">
        <!-- sd -->
      </div>
      <div class="modal-wrapper">
        <div class="modal-center">
          <div class="modal-container bg-cl-primary" ref="modal-content" :style="style">
            <header class="modal-header pt70 pb25 px65 h1 align-center serif weight-700 title-font cl-primary-orange bg-cl-primary" :style="parentColor" v-if="$slots.header">
              <div slot="close" class="modal-close p15" @click="close" data-testid="closeModalButton">
                <close-svg width="16px" height="100%" :color="parentColor" />
              </div>
              <slot name="header" />
            </header>
            <!-- cl-black -->
            <div class="modal-content pt30 pb60 px65" v-if="$slots.content">
              <slot name="content" />
            </div>
            <slot />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapMutations } from 'vuex'
import onEscapePress from '@vue-storefront/core/mixins/onEscapePress'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import CloseSvg from 'theme/components/theme/blocks/Icons/CloseSvg.vue'

export default {
  name: 'MobileModal',
  components: {
    CloseSvg
  },
  data () {
    return {
      isVisible: false
    }
  },
  methods: {
    onHide (name, state, params) {
      return name === this.name ? this.toggle(false) : false
    },
    onShow (name, state, params) {
      return name === this.name ? this.toggle(true) : false
    },
    onToggle (name, state, params) {
      if (name === this.name) {
        state = typeof state === 'undefined' ? !this.isVisible : state
        this.toggle(state)
      }
    },
    onEscapePress () {
      this.close()
    },
    ...mapMutations('ui', [
      'setOverlay'
    ]),
    toggle (state) {
      this.isVisible = state
      state ? this.setOverlay(state) : setTimeout(() => this.setOverlay(state), this.delay)
    },
    close () {
      this.toggle(false)
    }
  },
  watch: {
    isVisible (state) {
      if (state) {
        this.$nextTick(() => {
          disableBodyScroll(this.$refs['modal']);
        })
      } else {
        clearAllBodyScrollLocks();
      }
    }
  },
  beforeMount () {
    this.$bus.$on('modal-toggle', this.onToggle)
    this.$bus.$on('modal-show', this.onShow)
    this.$bus.$on('modal-hide', this.onHide)
  },
  beforeDestroy () {
    this.$bus.$off('modal-toggle', this.onToggle)
    this.$bus.$off('modal-show', this.onShow)
    this.$bus.$off('modal-hide', this.onHide)
  },
  mixins: [onEscapePress],
  props: {
    name: {
      required: true,
      type: String
    },
    delay: {
      required: false,
      type: Number,
      default: 300
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: ''
    }
  },
  computed: {
    style () {
      if (this.width && this.height) {
        return `width: ${this.width}px; height: ${this.height}px;`
      } else if (this.width) {
        return `heigth: ${this.width}px`
      } else if (this.height) {
        return `heigth: ${this.height}px`
      } else {
        return false
      }
    },
    parentColor () {
      if (this.color) {
        return `color: ${this.color};`
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/base/global_vars';
$z-index-modal: map-get($z-index, modal);

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: $z-index-modal;
  text-align: inherit;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  .modal-wrapper {
    display: table;
    height: 100%;
    width: 100%;
    table-layout: fixed;
    pointer-events: none;
    @media (max-width: 767px) {
      height: 251px;
      position: absolute;
      bottom: 0;
    }
  }

  .modal-center {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    width: 945px;
    margin: 0 auto;
    max-width: 100%;
    height: 100%;
    z-index: $z-index-modal+1;
    pointer-events: auto;

    @media (max-width: 600px) {
      min-height: 100%;
      min-width: 100%;
      margin: 0;
      height: 250px !important;
    }
  }

  .modal-header {
    position: relative;
    padding: 40px 0 0 0;
    > * {
        margin: 0;
    }

    @media (max-width: 600px) {
      padding: 25px 20px;
    }
  }

  .modal-content {
    @media (max-width: 600px) {
      padding: 30px 20px;
    }
  }

  .modal-close {
    position: absolute;
    cursor: pointer;
    right: 0;
    top: 0;
  }
}
@media (max-width: 767px) {
  .modal {
      .modal-container {
          border-radius: 0;
      }
  }
  .modal-close {
    font-size: 21px;
  }
  .modal-header {
    box-shadow: 0px 7px 14px #00000040;
    font-size: 16px !important;
    text-align: center;
    padding: 16px !important;
  }
  .modal-content {
    overflow-y: scroll;
    max-height: 140px;
    height: 140px;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }
  .close-box {
    z-index: 99;
    left: 0;
    width: 100%;
    position: absolute;
    height: calc( 100vh - 251px);
  }
}
</style>
